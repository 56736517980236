.list-tags {
  display: flex;
  flex-wrap: wrap;
}

.storybook-tag {
  width: fit-content;
  border: black solid 1px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  user-select: none;
  margin-right: 8px;
  padding: 0px 10px;
  font: 400 14px;
  border: none;
  line-height: 35px;
  height: 35px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.storybook-tag svg {
  margin-left: 8px;
  margin-top: 1px;
  cursor: pointer;
  color: #0000007d !important;
}

.magenta {
  color: #c41d7f;
  background: #fff0f6;
  border-color: #ffadd2;
}

.red {
  color: #cf1322;
  background: #fff1f0;
  border-color: #ffa39e;
}

.volcano {
  color: #d4380d;
  background: #fff2e8;
  border-color: #ffbb96;
}

.orange {
  color: #d46b08;
  background: #fff7e6;
  border-color: #ffd591;
}

.gold {
  color: #d48806;
  background: #fffbe6;
  border-color: #ffe58f;
}

.lime {
  color: #7cb305;
  background: #fcffe6;
  border-color: #eaff8f;
}

.green {
  color: #389e0d;
  background: #f6ffed;
  border-color: #b7eb8f;
}

.cyan {
  color: #08979c;
  background: #e6fffb;
  border-color: #87e8de;
}

.blue {
  color: #096dd9;
  background: #e6f7ff;
  border-color: #91d5ff;
}

.geekblue {
  color: #1d39c4;
  background: #f0f5ff;
  border-color: #adc6ff;
}

.purple {
  color: #531dab;
  background: #f9f0ff;
  border-color: #d3adf7;
}
